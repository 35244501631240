import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'

import Home from './Home'
import Policy from './Policy'


export default function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/privacy-policy" element={<Policy />} />
        </Routes>
      </Router>
    </div>
  );
}
