import 'antd/dist/antd.css'
import { Button, Carousel } from 'antd'
import 'remixicon/fonts/remixicon.css';
import '../src/vendor/bootstrap/css/bootstrap.min.css'
import '../src/vendor/bootstrap-icons/bootstrap-icons.css'
import '../src/css/style.css'
import {
    RightOutlined,
    FacebookOutlined,
    TwitterOutlined,
    InstagramOutlined,
    GooglePlusOutlined,
    LinkedinOutlined,
} from '@ant-design/icons'

function Policy() {
    return (
        <div>
            <header id="header" className="fixed-top d-flex align-items-center">
                <div className="container d-flex align-items-center">
                    <a href="/" className="logo me-auto">
                        <img src="assets/img/SPEED-Logo.png" alt="" />
                    </a>
                    <nav id="navbar" className="navbar order-last order-lg-0">
                        <ul>
                            <li>
                                <a className="nav-link scrollto active" href="#hero">
                                    Home
                                </a>
                            </li>
                            <li>
                                <a className="nav-link scrollto" href="#about">
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a className="nav-link scrollto" href="#pricing">
                                    Tracking
                                </a>
                            </li>
                            <li>
                                <a className="nav-link scrollto" href="#contact">
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                </div>
            </header>
            <section className='container'>
                <div className='col'>
                    <h3 className='text-center mt-5'>PRIVACY NOTICE</h3>
                    <p>
                        This Privacy Notice is issued by Speedworks Courier Services Corporation (referred to as "we," "us," or "our") and is designed to inform you about our practices concerning the collection, use, disclosure, and protection of your personal information in accordance with the Data Privacy Act of 2012 and other relevant laws and regulations.
                    </p>
                    <p><b>Personal Information We Collect</b></p>
                    <p>We collect and process the following personal information for the purpose of providing courier services and order tracking:</p>
                    <ul>
                        <li>Name</li>
                        <li>Address</li>
                        <li>Contact Number</li>
                    </ul>
                    <p><b>Why We Collect Personal Information</b></p>
                    <p>
                        We collect your personal information for the following purposes:
                    </p>
                    <ul>
                        <li>To facilitate the delivery of your packages and ensure they reach the intended recipient.</li>
                        <li>To provide you with order tracking services, enabling you to monitor the status and location of your shipments</li>
                        <li>To maintain a record of courier transactions for internal accounting and customer service purposes</li>
                    </ul>
                    <p><b>Sensitive and Personal Information</b></p>
                    <p>
                        Personal information includes data that identifies or can be used to identify you. Examples of personal information include your name, address, and contact number.
                    </p>
                    <p>
                        Sensitive information is a category of personal information that is particularly private and, by law, requires additional protection. This includes information related to your race, ethnic origin, marital status, age, color, and religious, philosophical, or political affiliations. We do not collect, process, or store sensitive information.
                    </p>
                    <p><b>Disclosure of Personal Information</b></p>
                    <p>
                        We will only disclose your personal information where your express prior consent has been obtained, and subject at all times to any laws, regulations, guidelines, and obligations applicable to the company. We may disclose your personal information to third parties, including partner courier companies, for the purpose of delivering your packages.
                    </p>
                    <p><b>Security of Personal Information</b></p>
                    <p>
                        We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. Our employees are trained to safeguard your data, and access is granted only on a need-to-know basis.
                    </p>
                    <p><b>Retention of Personal Information</b></p>
                    <p>
                        We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Notice and to comply with legal and regulatory requirements.
                    </p>
                    <p><b>Your Rights to Access and Correct Personal Information</b></p>
                    <p>
                        You have the right to access and correct your personal information held by us. If you wish to exercise these rights or have any questions or concerns about your personal information, please contact our Data Protection Officer at dataprivacy@speedworkscourier.com.
                    </p>
                    <p><b>Disclosure, Retention, and Use of Personal Information</b></p>
                    <p>
                        You have the right to make choices regarding the disclosure, retention, and use of your personal information. To express your preferences or opt-out of certain data processing, please contact our Data Protection Officer.
                    </p>
                    <p><b>Changes to this Privacy Notice</b></p>
                    <p>
                        We may update this Privacy Notice from time to time to reflect changes in our practices or legal requirements. Any updates will be published on our website.
                    </p>
                    <p><b>Contact Information</b></p>
                    <p>
                        If you have any questions or concerns about this Privacy Notice or our data practices, please contact our Data Protection Officer at dataprivacy@speedworkscourier.com.
                    </p>
                </div>
            </section>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h3 style={{ color: 'white' }}>
                                    Speedworks Courier Services Corporation<span></span>
                                </h3>
                                <p>
                                    SMS Bldg, <br />
                                    #15 Jade Street, Pilar Village,
                                    <br />
                                    Las Piñas City 1740 <br />
                                    <br />
                                    <strong>Phone:</strong> (+632) 8952-7411
                                    <br />
                                    <strong>Email:</strong> info@speedworkscourier.com
                                    <br />
                                </p>
                            </div>

                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4 style={{ color: 'white' }}>Useful Links</h4>
                                <ul>
                                    <li>
                                        <RightOutlined /> <a href="#">Home</a>
                                    </li>
                                    <li>
                                        <RightOutlined /> <a href="#">About us</a>
                                    </li>
                                    <li>
                                        <RightOutlined /> <a href="#">Tracking</a>
                                    </li>
                                    <li>
                                        <RightOutlined /> <a href="#">Terms of Service</a>
                                    </li>
                                    <li>
                                        <RightOutlined />{' '}
                                        <a href="/privacy-policy">Privacy Policy</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4 style={{ color: 'white' }}>Our Services</h4>
                                <ul>
                                    <li>
                                        <RightOutlined />{' '}
                                        <a href="#about">Door to Door Delivery Service</a>
                                    </li>
                                    <li>
                                        <RightOutlined /> <a href="#about">COD Service</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container d-md-flex py-4">
                    <div className="me-md-auto text-center text-md-start">
                        <div className="copyright">
                            &copy; Copyright . All Rights Reserved
                        </div>
                    </div>
                    <div className="social-links text-center text-md-end pt-3 pt-md-0">
                        <a href="#" className="twitter">
                            <TwitterOutlined />
                        </a>
                        <a href="#" className="facebook">
                            <FacebookOutlined />
                        </a>
                        <a href="#" className="instagram">
                            <InstagramOutlined />
                        </a>
                        <a href="#" className="google-plus">
                            <GooglePlusOutlined />
                        </a>
                        <a href="#" className="linkedin">
                            <LinkedinOutlined />
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Policy